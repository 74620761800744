import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"

import Menu from "../../components/ug-menu/menu"

// Images
import logoLight from "../../assets/img/brand/logo_loewy-light.png"
import logoDark from "../../assets/img/brand/logo_loewy-dark.png"

const MenuPage = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        brand(id: "6") {
          id
          title
          slug
          className
          image {
            url
          }
          menus (sort: "order") {
            title
            order
            file {
              url
            }
          }
        }
      }
    }
  `)

  const details = {
    brand: "Loewy",
    description:
      "A casual 1940s inspired bistro & bar serving classic brasserie dishes & signature cocktails",
    outlet: "",
    className: "loewy",
    loaderLogo: logoLight,
    headerLogo: logoDark,
    paymentLink: "http://hungree.id/cashier",
  }
  const blocks = data.strapi.brand
  return (
    <>
      <SEO
        title={`${details.brand} ${details.outlet} Menu`}
        description={details.description}
      />
      <Helmet>
        <body className={`bd-menu-landing ${details.className}`} />
      </Helmet>
      <Menu
        loaderImg={`https://qrweb-urea-lss9.antikode.dev${blocks.image[0].url}`}
        loaderAlt={blocks.title}
        headerImg={`https://qrweb-urea-lss9.antikode.dev${blocks.image[1].url}`}
        headerAlt={blocks.title}
        headerOutlet=""
        menus={
          blocks.menus.map((value, i)=>{
            return {
              title: value.title,
              link: value.file.url
            }
          })
        }
        slug={blocks.slug}
        paymentLink="http://hungree.id/cashier"
      />
    </>
  )
}

export default MenuPage
